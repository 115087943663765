<template>
    <div class="billing-address" v-if="customer.customer_address">
    <vs-input class="inputx mb-2" label-placeholder="Name*" 
      v-model="customer.customer_address.name" :maxlength="50"/>
    <vs-input class="inputx mb-2" :label-placeholder="$t('addition')" 
      v-model="customer.customer_address.additional" />
    <vs-input class="inputx mb-2" :label-placeholder="$t('street')+'*'" 
      v-model="customer.customer_address.street_address" :maxlength="50"/>
    <vs-input class="inputx mb-2" :label-placeholder="$t('zip-code')+'*'" 
      v-model="customer.customer_address.zip_code"/>
    <vs-input class="inputx mb-2" :label-placeholder="$t('city')+'*'" 
      v-model="customer.customer_address.city" :maxlength="50"/>
    <vs-select class="inputx mb-2" :label="$t('Bundesland')+'*'"
      v-model="customer.customer_address.state" >
      <vs-select-item v-for="e, idx in bundeslandData" :key="idx" :value="e" :text="e" />
    </vs-select>
    <vs-select class="inputx mb-2" :label="$t('country')+'*'"
      v-model="customer.customer_address.country">
      <vs-select-item v-for="e, idx in landData" :key="idx" :value="e" :text="e" />
    </vs-select>


    <vs-row class="w-100">
        <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="12">
          <vs-button class="pop-btn organ-btn" color="primary" type="filled" icon="done"
              @click="editCustomer()">
              {{$t('save')}}</vs-button>
        </vs-col>
    </vs-row>


  </div>


</template>

<script>
import Vue from 'vue';
import _ from 'lodash'

export default Vue.extend({
  name: 'CustomerAddress',
  async mounted() {
    await this.$store.dispatch('customer/loadCustomer', this.user.customer_id)
    this.customer = _.cloneDeep(this.$store.getters['customer/getCustomer'])
  },  

  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },

  data() {
    return {
      customer: {},
      landData: ['Deutschland', 'Österreich'],
      bundeslandData: ['Baden-Württemberg', 'Bayern', 'Berlin', 'Brandenburg','Bremen', 'Hamburg', 'Hessen', 'Mecklenburg-Vorpommern', 'Niedersachsen', 'Nordrhein-Westfalen',
      'Rheinland-Pfalz', 'Saarland', 'Sachsen', 'Sachsen-Anhalt', 'Schleswig-Holstein', 'Thüringen']
    }
  },

  methods: {
    async editCustomer() {
      if (!this.validateInputs()) {
        return;
      }
      let status = await this.$store.dispatch('customer/updateCustomer', this.customer)
      if (status === 200) {
        this.$vs.notify({
          color: "success",
          position: "bottom-right",
          title: "Daten wurden gespeichert",
        });
      } else {
        errorNotifier(status)
      }
    },
    validateInputs() {
      //  Check Name
      if (this.customer.customer_address.name.trim() === '') {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Bitte Name eingeben.",
          text: "",
        });
        return false;
      }
      if(this.customer.customer_address.name.trim().length > 50) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Der Name darf nicht mehr als 50 Zeichen enthalten.",
          text: "",
        });
        return false;
      }

      if(this.customer.customer_address.street_address.trim().length > 50) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Die Straße darf nicht mehr als 50 Zeichen enthalten.",
          text: "",
        });
        return false;
      }

      if(this.customer.customer_address.city.trim().length > 50) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Der Ort darf nicht mehr als 50 Zeichen enthalten.",
          text: "",
        });
        return false;
      }

      if (this.customer.customer_address.street_address.trim() === '') {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Bitte Straße eingeben.",
          text: "",
        });
        return false;
      }

      if (this.customer.customer_address.zip_code.trim() === '') {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Bitte PLZ eingeben.",
          text: "",
        });
        return false;
      }
      if (isNaN(this.customer.customer_address.zip_code)) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Ungültige PLZ",
          text: "PLZ fehlerhaft, bitte überprüfen!",
        });
        return false;
      }
      if ((this.customer.customer_address.zip_code.length != 5) && (this.customer.customer_address.country==='Deutschland')) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Ungültige PLZ",
          text: "PLZ fehlerhaft, bitte überprüfen!",
        });
        return false;
      }
      if ((this.customer.customer_address.zip_code.length != 4) && (this.customer.customer_address.country==='Österreich')) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Ungültige PLZ",
          text: "PLZ fehlerhaft, bitte überprüfen!",
        });
        return false;
      }
      if (this.customer.customer_address.city.trim() === '') {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Bitte Ort eingeben.",
          text: "",
        });
        return false;
      }

      if (this.customer.customer_address.state.trim() === '') {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Bitte Bundesland eingeben.",
          text: "",
        });
        return false;
      }

      if (this.customer.customer_address.country.trim() === '') {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Bitte Land eingeben.",
          text: "",
        });
        return false;
      }
      return true;
    }
  }


});
</script>
<style>

</style>
